// TableView.jsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TableView() {
  const location = useLocation();
  const navigate = useNavigate();
  const [asignaciones, setAsignaciones] = useState([]);
  const [guiaDespachos, setGuiaDespachos] = useState([]);

  useEffect(() => {
    if (location.state) {
      const { asignaciones: asignacionesData, quantities: quantitiesData } = location.state;

      if (asignacionesData && asignacionesData.length > 0) {
        setAsignaciones(asignacionesData);
      }

      if (quantitiesData && quantitiesData.length > 0) {
        setGuiaDespachos(quantitiesData);
      }
    } else {
      // Si no hay datos, redirigir al inicio y mostrar error
      toast.error("⚠️ No hay datos para mostrar. Por favor, realice una búsqueda.", {
        autoClose: false,
        style: { 
          fontSize: '20px', 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '20px', 
          borderRadius: '10px' 
        },
      });
      navigate("/search");
    }
  }, [location, navigate]);

  const calculateMaxDate = (fechaDespacho) => {
    if (fechaDespacho === "Por definir") {
      return "Por definir";
    }

    const fechaDespachoDate = new Date(fechaDespacho);
    let fechaMaxima = new Date(fechaDespachoDate);
    fechaMaxima.setDate(fechaMaxima.getDate() + 5); // Sumar cinco días

    // Ajustar para que no caiga en fin de semana
    if (fechaMaxima.getDay() === 6) { // Sábado
      fechaMaxima.setDate(fechaMaxima.getDate() + 2);
    } else if (fechaMaxima.getDay() === 0) { // Domingo
      fechaMaxima.setDate(fechaMaxima.getDate() + 1);
    }

    return fechaMaxima.toLocaleDateString('es-ES');
  };

  return (
    <div className="table-view">
      <ToastContainer />

      <div
        className="fixed top-0 left-0 w-full bg-white p-4 flex justify-between items-center shadow-md"
        style={{ height: '80px', zIndex: 1000 }}
      >
        <img
          src="/CNA_black.png"
          alt="Logo CNA"
          className="h-20 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <button
          onClick={() => navigate('/search')}
          style={{ backgroundColor: 'rgb(128, 182, 26)' }}
          className="px-4 py-2 text-white font-bold rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          Regresar al inicio
        </button>
      </div>
      
      <div className="mt-20" style={{ paddingTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
        {/* Tabla de Asignaciones */}
        {asignaciones.length > 0 && (
          <div className="bg-white bg-opacity-75 p-3 m-4 rounded shadow-md w-full max-w-7xl overflow-x-auto">
            <h2 className="text-2xl font-bold mb-4">Pendiente de despacho</h2>
            <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700 rounded mb-8">
              <thead className="sticky top-0 z-10" style={{ backgroundColor: 'rgb(0, 149, 58)' }}>
                <tr>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Estimada de Despacho</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cliente</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Descripción del Producto</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cantidad</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Comuna</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Dirección</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
                {asignaciones.map((asignacion, index) => (
                  <tr key={index} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600">
                    <td className="p-4 text-sm text-black dark:text-gray-400 font-extrabold">
                      {asignacion.fecha_despacho !== "Por definir" ? 
                        `${new Date(asignacion.fecha_despacho).toLocaleDateString('es-ES')} - ${calculateMaxDate(asignacion.fecha_despacho)}`
                        : "Por definir"
                      }
                    </td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{asignacion["Nombre Cliente"]}</td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{asignacion["Descripción Producto"]}</td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{asignacion["Cantidad en Solicitud"]}</td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{asignacion.Comuna}</td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{asignacion.Dirección}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Tabla de Guías de Despacho */}
        {guiaDespachos.length > 0 && (
          <div className="bg-white bg-opacity-75 p-3 m-4 rounded shadow-md w-full max-w-7xl overflow-x-auto">
            <h2 className="text-2xl font-bold mb-4">Despachado</h2>
            <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700 rounded">
              <thead className="sticky top-0 z-10" style={{ backgroundColor: 'rgb(0, 149, 58)' }}>
                <tr>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Nombre producto</th>
                  <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cantidad</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
                {guiaDespachos.map((guia, index) => (
                  <tr key={index} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600">
                    <td className="p-4 text-sm text-black dark:text-gray-400">{guia["Nombre producto"]}</td>
                    <td className="p-4 text-sm text-black dark:text-gray-400">{guia.Quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Mensajes informativos si hay datos en alguna tabla */}
        {asignaciones.length > 0 || guiaDespachos.length > 0 ? null : (
          <div className="text-center">
            <p className="text-red-500 text-lg">⚠️ No se encontraron resultados en ninguna tabla.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TableView;

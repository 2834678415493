// SearchView.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SearchView() {
  const [searchTermOC, setSearchTermOC] = useState("");
  const [searchTermSolicitud, setSearchTermSolicitud] = useState("");
  const [errorOC, setErrorOC] = useState(false);
  const [errorSolicitud, setErrorSolicitud] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    // Limpiar errores previos
    setErrorOC(false);
    setErrorSolicitud(false);
    setErrorMessage("");

    let hasError = false;
    if (!searchTermOC) {
      setErrorOC(true);
      hasError = true;
    }
    if (!searchTermSolicitud) {
      setErrorSolicitud(true);
      hasError = true;
    }

    if (hasError) return;

    try {
      const token = localStorage.getItem("token");

      // Ejecutar ambas consultas en paralelo usando Promise.allSettled
      const [asignacionesResult, quantitiesResult] = await Promise.allSettled([
        axios.get("https://api.cnagro.cl/buscar-asignaciones", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            numeroOC: searchTermOC,
            numeroSolicitud: searchTermSolicitud,
          },
        }),
        axios.get("https://api.cnagro.cl/buscar-suma-quantities", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            numeroOC: searchTermOC,
            numeroSolicitud: searchTermSolicitud,
          },
        }),
      ]);

      let asignacionesData = [];
      let quantitiesData = [];

      // Manejar el resultado de buscar-asignaciones
      if (asignacionesResult.status === "fulfilled") {
        asignacionesData = asignacionesResult.value.data;
      } else {
        console.error("Error en buscar-asignaciones:", asignacionesResult.reason);
      }

      // Manejar el resultado de buscar-suma-quantities
      if (quantitiesResult.status === "fulfilled") {
        quantitiesData = quantitiesResult.value.data;
      } else {
        console.error("Error en buscar-suma-quantities:", quantitiesResult.reason);
      }

      // Verificar si ambas respuestas están vacías o contienen error
      const asignacionesVacias = asignacionesData.length === 0;
      const quantitiesVacias = quantitiesData.length === 0;

      if (asignacionesVacias && quantitiesVacias) {
        // Si ambas respuestas están vacías, mostrar un mensaje de error
        toast.error("⚠️ No se encontraron resultados en ambas tablas. Por favor, verifique los parámetros de búsqueda.", {
          autoClose: false, // No se cerrará automáticamente
          style: { 
            fontSize: '20px', 
            backgroundColor: 'red', 
            color: 'white', 
            padding: '20px', 
            borderRadius: '10px' 
          },
        });
        return; // No navegamos a la vista de tablas
      }

      // Navegar a /table pasando ambos datos en el estado
      navigate("/table", {
        state: {
          asignaciones: asignacionesData,
          quantities: quantitiesData,
        },
      });
    } catch (error) {
      console.error("Hubo un error en las consultas:", error);
      toast.error("Hubo un error al realizar las consultas.", {
        autoClose: false,
        style: { 
          fontSize: '20px', 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '20px', 
          borderRadius: '10px' 
        },
      });
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://api.cnagro.cl/login", {
        username,
        password,
      });

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        navigate("/fulltable");
      } else {
        toast.error("⚠️ Usuario o contraseña incorrectos", {
          autoClose: false,
          style: { 
            fontSize: '20px', 
            backgroundColor: 'red', 
            color: 'white', 
            padding: '20px', 
            borderRadius: '10px' 
          },
        });
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      toast.error("⚠️ Hubo un error al iniciar sesión", {
        autoClose: false,
        style: { 
          fontSize: '20px', 
          backgroundColor: 'red', 
          color: 'white', 
          padding: '20px', 
          borderRadius: '10px' 
        },
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen">
      <ToastContainer />

      <div className="fixed top-0 left-0 w-full bg-white p-4 shadow-md flex justify-between items-center">
        <img
          src="/CNA_black.png"
          alt="Logo CNA"
          className="max-h-8 lg:max-h-20 md:max-h-12 sm:max-h-16"
        />

        <img
          src="/logoST2.png"
          alt="Logo ST2"
          className="hidden sm:block max-h-6 lg:max-h-20 md:max-h-10"
        />

        <div className="flex space-x-4 items-center">
          <div className="flex flex-col space-y-2">
            <input
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleLogin}
              style={{ backgroundColor: "rgb(128, 182, 26)" }}
              className="px-4 py-2 bg-red-500 text-white font-bold rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Personal CNA
            </button>
          </div>
        </div>
      </div>

      <div className="flex-grow flex flex-col items-center justify-center space-y-4">
        <div className="bg-white bg-opacity-75 p-8 rounded-2xl shadow-md flex flex-col items-center justify-center space-y-4">
          <p className="font-bold text-neutral-600 text-center">
            Ingrese su orden de compra y número <br></br>
            de solicitud para ver la fecha estimada de despacho.
          </p>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Número de OC"
              value={searchTermOC}
              onChange={(e) => setSearchTermOC(e.target.value)}
              className={`p-2 border ${
                errorOC ? "border-red-500" : "border-gray-300"
              } rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full`}
            />
            {errorOC && (
              <div className="tooltip">El número de OC es requerido</div>
            )}
          </div>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Número de Solicitud"
              value={searchTermSolicitud}
              onChange={(e) => setSearchTermSolicitud(e.target.value)}
              className={`p-2 border ${
                errorSolicitud ? "border-red-500" : "border-gray-300"
              } rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full`}
            />
            {errorSolicitud && (
              <div className="tooltip">El número de solicitud es requerido</div>
            )}
          </div>
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            style={{ backgroundColor: "rgb(128, 182, 26)" }}
          >
            Buscar
          </button>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}

export default SearchView;
